import * as _ from 'lodash';

interface IPort {
  lat: number
  lon: number
  name: string
  unlocode: string
}

export const getPathCoordinatesLayers = (data: any = {}) => {
  const mapDataLayers: any[] = [];
  if (data?.optimal_route) {
    const optimalRouteData = data.optimal_route;
    const actualRouteData = data.actual_route;
    mapDataLayers.push(...convertToLayersData(optimalRouteData));
    mapDataLayers.push(...convertToLayersData(actualRouteData, true));
  }
  return mapDataLayers;
};

const convertToLayersData = (data: any, isActualPath: boolean = false) => {
  const mapDataLayers: any[] = [];
  const pathPaint = isActualPath
    ? { 'line-color': '#5F747D', 'line-width': 2 }
    : { 'line-color': '#afafaf', 'line-width': 2, 'line-dasharray': [2, 1.5] };

  if (data?.route_details) {
    data.route_details.forEach((routeDetail: any) => {
      if (routeDetail.locations) {
        routeDetail.locations.forEach((location: any) => {
          const coordinates = location.route.map((plot: any) => [plot.lon, plot.lat]);
          mapDataLayers.push({
            isActualPath,
            id: Math.random().toString(36).substring(7),
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: convertCoordinatesToGeocoding(coordinates),
                },
              },
            },
            paint: pathPaint,
          });
        });
      }
    });
  }
  return mapDataLayers;
};

export const convertCoordinatesToGeocoding = (coordinates: any[]) => {
  return coordinates.map((entry, i) => {
    if (i === 0) {
      return [entry[0], entry[1]];
    }
    else {
      const previousLongitude = coordinates[i - 1][0];
      const longitude = (entry[0] += entry[0] - previousLongitude > 180
        ? -360
        : previousLongitude - entry[0] > 180
          ? 360
          : 0);
      return [longitude, entry[1]];
    }
  });
};

export const getLiveCoordinates = (data: any = {}, masterReport: any = {}) => {
  const latestAis = _.get(data, 'current_vessel.latest_ais', {});
  if (!_.isEmpty(latestAis)) {
    return [latestAis.lon, latestAis.lat];
  }
  const { $debug }: any = useNuxtApp();
  if (!masterReport.arrived) {
    $debug.log('No live coordinates found, using last actual path coordinates');
    try {
      const actualRouteData = _.get(data, 'actual_route', {});
      const actualPathLayers = convertToLayersData(actualRouteData) || [];
      const lastLayer = _.first(actualPathLayers);
      return _.get(lastLayer, 'source.data.geometry.coordinates[0]', []);
    }
    catch (_e) {
      return [];
    }
  }

  return [];
};

export const getPolPodPot = (data: any, masterReport: any) => {
  const allPortsList: IPort[] = [];
  const tsPortsList: IPort[] = [];

  if (data?.optimal_route) {
    const optimalRouteData = data.optimal_route;
    optimalRouteData.route_details.forEach(({ locations }: any) => {
      locations.forEach((location: any) => {
        allPortsList.push(location.source_port as IPort);
      });
    });

    const tsPortCodes: string[] = masterReport?.transhipment_port_codes || [];
    tsPortCodes.forEach((portCode: string) => {
      const port = allPortsList.find((item: IPort) => item.unlocode === portCode);
      if (port) {
        tsPortsList.push(port);
      }
    });

    return {
      pol: optimalRouteData.POL,
      pod: optimalRouteData.POD,
      pot: tsPortsList,
    };
  }

  return {
    pol: null,
    pod: null,
    pot: [],
  };
};

export const hasActualDeparture = (_port_code: string): boolean => {
  return true;
};

const toRad = (n: number) => n * (Math.PI / 180);

const toDeg = (n: number) => n * (180 / Math.PI);

export const middlePoint = (pol: any, pod: any) => {
  if (!pol || !pod) return [0, 0];

  let { lat: lat1, lon: lng1 } = pol;
  const { lat: lat2, lon: lng2 } = pod;

  const dLng = toRad(lng2 - lng1);
  lat1 = toRad(lat1);
  const lat2Rad = toRad(lat2);
  lng1 = toRad(lng1);

  const bX = Math.cos(lat2Rad) * Math.cos(dLng);
  const bY = Math.cos(lat2Rad) * Math.sin(dLng);
  const lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2Rad),
    Math.sqrt((Math.cos(lat1) + bX) ** 2 + bY ** 2 + 70),
  );
  const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

  return lng3 || lat3 ? [toDeg(lng3), toDeg(lat3)] : [1, 1];
};
